@import './customFonts.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add this to your App.css */
/* .textarea-autosize:focus {
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-500), 0 0 0 4px var(--chakra-colors-blue-200) !important;
} */

:root {
  --chakra-colors-blue-500: #4299e1;
  /* Example blue color */
  --chakra-colors-blue-200: #bee3f8;
  /* Lighter blue color */
}
