body {
    background-color: #0d0d19;
    color: #e0e0e0;
    font-family: 'Lexend', sans-serif;
}

.page-container {
    padding: 20px;
    min-height: 100vh;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #1e1e2e;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header-title {
    font-size: 24px;
    font-weight: bold;
    color: #3b6aec;
}

.form-control {
    margin-bottom: 16px;
}

.map-container {
    height: 80vh;
    width: 100%;
    border-radius: 12px;
    border: 2px solid #2b2b40;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
}

.map-svg {
    width: 100%;
    height: 100%;
}

.building {
    transition: all 0.3s ease;
}

.building:hover {
    filter: brightness(1.2);
}

.capital-city {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .page-container {
        padding: 10px;
    }

    .header-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-title {
        margin-bottom: 10px;
    }

    .map-container {
        height: 60vh;
    }
}

/* Styles for ReactSVGPanZoom */
.react-svg-pan-zoom_toolbar {
    background-color: #1e1e2e !important;
}

.react-svg-pan-zoom_toolbar-button {
    color: #e0e0e0 !important;
}

.react-svg-pan-zoom_toolbar-button:hover {
    background-color: #2b2b40 !important;
}