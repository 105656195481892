/* customFonts.css */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Arial:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Comic+Sans+MS:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Courier+New:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Georgia:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Helvetica:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lucida:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Tahoma:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Verdana:wght@400;700&display=swap'); */

/* .ql-font-arial { font-family: 'Arial', sans-serif; } */
.ql-font-comic-sans-ms {
    font-family: 'Comic Sans MS', cursive;
}

.ql-font-courier-new {
    font-family: 'Courier New', monospace;
}

.ql-font-georgia {
    font-family: 'Georgia', serif;
}

.ql-font-helvetica {
    font-family: 'Helvetica', sans-serif;
}

/* .ql-font-lucida { font-family: 'Lucida', sans-serif; } */
.ql-font-tahoma {
    font-family: 'Tahoma', sans-serif;
}

.ql-font-times-new-roman {
    font-family: 'Times New Roman', serif;
}

.ql-font-verdana {
    font-family: 'Verdana', sans-serif;
}

.ql-font-lexend {
    font-family: 'Lexend', sans-serif;
}

.ql-editor {
    height: 100%;
    overflow-y: auto;
}