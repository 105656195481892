body {
    background-color: #0d0d19;
    color: #e0e0e0;
    font-family: 'Lexend', sans-serif;
}

.custom-card {
    background-color: #1e1e2e;
    border: 2px solid #2b2b40;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 16px;
    margin: 16px 0;
}

.custom-button {
    background: linear-gradient(135deg, #1e3a8a, #3b82f6);
    color: #ffffff;
    border-radius: 50px;
    padding: 12px 24px;
    transition: all 0.3s ease;
}

.custom-button:hover {
    background: linear-gradient(135deg, #3b82f6, #1e3a8a);
}

.custom-select {
    background-color: #2b2b40;
    border: 1px solid #4a4a5e;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-select:hover {
    background-color: #3a3a4e;
}

.header-icon {
    background-color: #2b2b40;
    border-radius: 50%;
    padding: 16px;
    margin-right: 8px;
}

.header-title {
    font-size: 24px;
    font-weight: bold;
    color: #3b6aec;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.form-control {
    margin-bottom: 24px;
}

.resource-card,
.building-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid #3a3a4e;
    border-radius: 12px;
    background-color: #1e1e2e;
    margin: 8px;
    width: 100%;
}

.resource-card>*,
.building-card>* {
    margin: 4px 0;
}
